import React from 'react';

import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

import AkadLogoTagline from 'images/akad-logo-tagline--white.svg';

import './main-footer.scss';

const block = 'main-footer';

const trackEvent = {
  assitancy: () => {
    trackCustomEvent({
      category: 'Download de arquivo',
      action: 'Click',
      label: 'Assistência 24 horas',
    });
  },
  conditionsGeneral: () => {
    trackCustomEvent({
      category: 'Download de arquivo',
      action: 'Click',
      label: 'Condições Gerais',
    });
  },
  conditionsGeneralResponsibility: () => {
    trackCustomEvent({
      category: 'Download de arquivo',
      action: 'Click',
      label: 'Condições Gerais Responsabilidade Civil',
    });
  },
  conditionsProfitBlocker: () => {
    trackCustomEvent({
      category: 'Download de arquivo',
      action: 'Click',
      label: 'Condições Gerais Lucros Cessantes',
    });
  },
};

const MainFooter = () => {
  return (
    <footer className={`${block}`}>
      <main className={`${block}__main container--large`}>
        <section className={`${block}__brand`}>
          <img
            src={AkadLogoTagline}
            alt="Akad Seguros: Faz tudo para vocês fazer mais."
          />
        </section>
        <div>
          <div className={`${block}__grid`}>
            <section>
              <div className={`${block}__block`}>
                <h4 className={`${block}__title`}>Ouvidoria Akad Seguros</h4>
                <a className={`${block}__item`} href="tel:0800 773 3103">
                  0800 773 3103
                </a>
              </div>
              <div className={`${block}__block`}>
                <h4 className={`${block}__title`}>
                  SAC (Atendimento 24 horas)
                </h4>
                <a className={`${block}__item`} href="tel:4000-1246">
                  4000-1246 (capitais e regiões metropolitanas)
                </a>
                <a className={`${block}__item`} href="tel:0800 942 2746">
                  0800 942 2746 (demais regiões){' '}
                </a>
              </div>
            </section>
            <section>
              <div className={`${block}__block`}>
                <h4 className={`${block}__title`}>Central de Sinistros</h4>
                <a className={`${block}__item`} href="tel:0800 777 2746">
                  0800 777 2746
                </a>
                <a
                  href="https://sinistro.akadseguros.com.br/"
                  className={`${block}__item ${block}__item--external`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Avisar um sinistro online
                </a>
              </div>
            </section>
            <section>
              <div className={`${block}__block`}>
                <h4 className={`${block}__title`}>
                  <span className="material-icons icon">download</span>
                  Documentos Relacionados
                </h4>
                <a
                  href="/pdf/Assistencia-Empresarial-IKE-2023.pdf"
                  onClick={trackEvent.assitancy}
                  className={`${block}__item ${block}__item--download`}
                  data-file-type="PDF"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Assistência 24 horas
                </a>
                <a
                  href="/pdf/CC_Empres_Completo_01-12-2022.pdf"
                  onClick={trackEvent.conditionsGeneral}
                  className={`${block}__item ${block}__item--download`}
                  data-file-type="PDF"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Condições Gerais Patrimoniais
                </a>
                <a
                  href="/pdf/RCG-secundario_01-12-2022.pdf"
                  onClick={trackEvent.conditionsGeneralResponsibility}
                  className={`${block}__item ${block}__item--download`}
                  data-file-type="PDF"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Condições Gerais Responsabilidade Civil
                </a>
                <a
                  href="/pdf/Condicoes_LC_secundario_CE_RD_01-12-2022.pdf"
                  onClick={trackEvent.conditionsProfitBlocker}
                  className={`${block}__item ${block}__item--download`}
                  data-file-type="PDF"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Condições Gerais Lucros Cessantes
                </a>
              </div>
            </section>
          </div>
          <section className={`${block}__legal`}>
            <p>
              Processo Susep No <b>15414.0003402012-98</b>,{' '}
              <b>15414.0004782012-97</b> e <b>15414.9013852013-62</b>. O
              registro desse plano na SUSEP não implica, por parte da autarquia,
              incentivo ou recomendação à sua comercialização.
            </p>
          </section>
        </div>
      </main>
    </footer>
  );
};

export default MainFooter;
