import {
  simulationAkadBrokerOffice,
  simulationBusinessOffice,
  simulationDoctorsOffice,
} from 'mock/simulationData';

export const plansData = [
  {
    productType: 'akad-broker-office',
    simulationData: simulationAkadBrokerOffice,
    title: 'Escritório Corretor de Seguros',
    icon: 'stars',
    isExclusive: true,
    pricing: {
      currency: 'R$',
      amount: '210,74',
      installment: '2x',
    },
    features: [
      {
        icon: 'local_fire_department',
        description: 'Incêndio, Queda de Raio e Explosão',
        currency: 'R$',
        amount: '300 Mil',
      },
      {
        icon: 'bolt',
        description: 'Danos Elétricos',
        currency: 'R$',
        amount: '15 Mil',
      },
      {
        icon: 'computer',
        description: 'Eq. Eletrônicos (s/ Roubo)',
        currency: 'R$',
        amount: '7.5 Mil',
      },
      {
        icon: 'request_quote',
        description: 'Lucros cessantes',
        currency: 'R$',
        amount: '30 Mil',
      },
      {
        icon: 'savings',
        description: 'Perda ou Pagamento de Aluguel',
        currency: 'R$',
        amount: '15 Mil',
      },
      {
        icon: 'account_circle',
        description: 'Responsabilidade Civil Empregador',
        currency: 'R$',
        amount: '30 Mil',
      },
      {
        icon: 'engineering',
        description: 'Responsabilidade Civil Operações',
        currency: 'R$',
        amount: '30 Mil',
      },
      {
        icon: 'diamond',
        description: 'Roubo de Bens',
        currency: 'R$',
        amount: '7.5 Mil',
      },
      {
        icon: 'air',
        description:
          'Vendaval, Ciclone, Tornado, Furacão, Granizo e Impacto de Veículos',
        currency: 'R$',
        amount: '30 Mil',
      },
      {
        icon: 'construction',
        description: 'Assistência 24 hrs',
        currency: '',
        amount: '',
      },
    ],
  },
  {
    productType: 'business-office',
    simulationData: simulationBusinessOffice,
    title: 'Escritório',
    icon: 'corporate_fare',
    specialTag: null,
    pricing: {
      currency: 'R$',
      amount: '306,82',
      installment: '3x',
    },
    features: [
      {
        icon: 'local_fire_department',
        description: 'Incêndio, Queda de Raio e Explosão',
        currency: 'R$',
        amount: '300 Mil',
      },
      {
        icon: 'bolt',
        description: 'Danos Elétricos',
        currency: 'R$',
        amount: '15 Mil',
      },
      {
        icon: 'computer',
        description: 'Eq. Eletrônicos (s/ Roubo)',
        currency: 'R$',
        amount: '7.5 Mil',
      },
      {
        icon: 'request_quote',
        description: 'Lucros cessantes',
        currency: 'R$',
        amount: '30 Mil',
      },
      {
        icon: 'savings',
        description: 'Perda ou Pagamento de Aluguel',
        currency: 'R$',
        amount: '15 Mil',
      },
      {
        icon: 'account_circle',
        description: 'Responsabilidade Civil Empregador',
        currency: 'R$',
        amount: '30 Mil',
      },
      {
        icon: 'engineering',
        description: 'Responsabilidade Civil Operações',
        currency: 'R$',
        amount: '30 Mil',
      },
      {
        icon: 'diamond',
        description: 'Roubo de Bens',
        currency: 'R$',
        amount: '7.5 Mil',
      },
      {
        icon: 'air',
        description:
          'Vendaval, Ciclone, Tornado, Furacão, Granizo e Impacto de Veículos',
        currency: 'R$',
        amount: '30 Mil',
      },
      {
        icon: 'construction',
        description: 'Assistência 24 hrs',
        currency: '',
        amount: '',
      },
    ],
  },
  {
    productType: 'doctors-office',
    simulationData: simulationDoctorsOffice,
    title: 'Consultório',
    icon: 'apartment',
    specialTag: null,
    pricing: {
      currency: 'R$',
      amount: '327,21',
      installment: '3x',
    },
    features: [
      {
        icon: 'local_fire_department',
        description: 'Incêndio, Queda de Raio e Explosão',
        currency: 'R$',
        amount: '300 Mil',
      },
      {
        icon: 'bolt',
        description: 'Danos Elétricos',
        currency: 'R$',
        amount: '15 Mil',
      },
      {
        icon: 'computer',
        description: 'Eq. Eletrônicos (s/ Roubo)',
        currency: 'R$',
        amount: '7.5 Mil',
      },
      {
        icon: 'request_quote',
        description: 'Lucros cessantes',
        currency: 'R$',
        amount: '30 Mil',
      },
      {
        icon: 'savings',
        description: 'Perda ou Pagamento de Aluguel',
        currency: 'R$',
        amount: '15 Mil',
      },
      {
        icon: 'account_circle',
        description: 'Responsabilidade Civil Empregador',
        currency: 'R$',
        amount: '30 Mil',
      },
      {
        icon: 'engineering',
        description: 'Responsabilidade Civil Operações',
        currency: 'R$',
        amount: '30 Mil',
      },
      {
        icon: 'diamond',
        description: 'Roubo de Bens',
        currency: 'R$',
        amount: '7.5 Mil',
      },
      {
        icon: 'air',
        description:
          'Vendaval, Ciclone, Tornado, Furacão, Granizo e Impacto de Veículos',
        currency: 'R$',
        amount: '30 Mil',
      },
      {
        icon: 'construction',
        description: 'Assistência 24 hrs',
        currency: '',
        amount: '',
      },
    ],
  },
];
