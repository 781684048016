import React from 'react';

import Button from 'components/button/button';

import './plan-card.scss';

const block = 'plan-card';

const PlanCard = ({ plan, btnId }) => {
  const { isExclusive, title, icon, features, pricing, handler } = plan;

  return (
    <div className={`${block}`}>
      {isExclusive && (
        <div className={`${block}__tag`}>
          <p>Exclusivo para corretor de seguros Akad</p>
        </div>
      )}
      <div className={`${block}__body`}>
        <div className={`${block}__header`}>
          <span className="material-icons icon">{icon}</span>
          <h3 className={`${block}__title`}>{title}</h3>
        </div>
        <div className={`${block}__pricing`}>
          <p className={`${block}__price-caption`}>a partir de</p>
          <p className={`${block}__price`}>
            <span>{pricing.currency}</span>
            {pricing.amount.split(',')[0]}
            <small>,{pricing.amount.split(',')[1]}</small>
          </p>
          <p className={`${block}__price-caption`}>
            em até {pricing.installment} sem juros
          </p>
        </div>
        <div className={`${block}__cta`}>
          <Button theme="secondary" size="large" id={`${block}-${btnId}`} onClick={() => handler()}>
            Cotar agora
          </Button>
        </div>
        <div className={`${block}__features`}>
          <table>
            <thead class="visually-hidden">
              <tr>
                <th>Ícone</th>
                <th>Cobertura</th>
                <th>Limite</th>
                <th>Prêmio</th>
              </tr>
            </thead>
            <tbody>
              {features.map((feature) => (
                <tr>
                  <td className={`${block}__features-icon`}>
                    <span className="material-icons icon">{feature.icon}</span>
                  </td>
                  <td className={`${block}__features-description`}>
                    {feature.description}
                  </td>
                  <td className={`${block}__features-currency`}>
                    {feature.currency || ''}
                  </td>
                  <td className={`${block}__features-prize`}>
                    {feature.amount || ''}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
