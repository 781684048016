import React, { useContext, useMemo } from 'react';
import './main-header.scss';
import akadLogoSvg from '../../images/akad-logo.svg';
import Button from '../../components/button/button';
import { Link } from 'gatsby';
import { DataContext } from '../../data/DataProvider';
import { useLocation } from '@reach/router';

const block = 'main-header';
const MainHeader = () => {
  const { quotation } = useContext(DataContext);

  const headerButton = useMemo(() => {
    if (quotation === null || !quotation?.propostaId) {
      return {
        show: true,
        navigation: `${process.env.GATSBY_URL_DIGITAL}`,
        label: 'Voltar ao Digital',
      };
    }
    if (window.location.pathname !== '/resumo') {
      return {
        show: false,
      };
    }

    if (quotation?.propostaId) {
      if (quotation.isProposal) {
        return {
          show: true,
          navigation: `${process.env.GATSBY_URL_DIGITAL}/protector/ferramentas/propostas`,
          label: 'Voltar para Propostas',
        };
      } else if (!quotation.isProposal) {
        return {
          show: true,
          navigation: `${process.env.GATSBY_URL_DIGITAL}/protector/ferramentas/cotacoes`,
          label: 'Voltar para Cotações',
        };
      } else {
        return {
          show: false,
        };
      }
    }
  });
  const doButtonNavigation = () => {
    window.location.href = headerButton?.navigation;
  };

  const location = useLocation();
  const { pathname } = location;

  const isCurrentPage = (path) => {
    return pathname === path;
  };

  return (
    <header className={block}>
      <div className={`container--header ${block}__content`}>
        <Link to="/">
          <img src={akadLogoSvg} width="200px" alt="Logo Akad Seguros" />
        </Link>
        <div className={`${block}__button-wrapper`}>
          <Link
            to="/"
            className={
              isCurrentPage('/') ? 'link-menu link-menu__active' : 'link-menu'
            }
          >
            Início
          </Link>
          <Link
            to="/perguntas-frequentes"
            className={
              isCurrentPage('/perguntas-frequentes')
                ? 'link-menu link-menu__active'
                : 'link-menu'
            }
          >
            Perguntas Frequentes
          </Link>
          {headerButton?.show && (
            <Button
              className={`${block}__cancel-edit`}
              theme={'secondary'}
              type="button"
              onClick={doButtonNavigation}
            >
              {headerButton?.label}
            </Button>
          )}
        </div>
      </div>
    </header>
  );
};

export default MainHeader;
