import { navigate } from 'gatsby';

const nonAuthPages = ['/perguntas-frequentes/'];
const handleInvalidSession = () => {
  if (typeof window !== 'undefined') {
    navigate('/nao-autorizado');
  }
};

export { handleInvalidSession, nonAuthPages };
