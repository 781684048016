import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './button.scss';

const block = 'button';
const themes = ['primary', 'secondary', 'tertiary'];
const sizes = ['large', 'small'];
const Button = React.forwardRef(
  ({ theme, size, children, className, ...rest }, ref) => {
    return (
      <button
        ref={ref}
        className={cn(
          block,
          className,
          `${block}--${theme}`,
          `${block}--${size}`
        )}
        {...rest}
      >
        {children}
      </button>
    );
  }
);

Button.propTypes = {
  theme: PropTypes.oneOf(themes).isRequired,
  size: PropTypes.oneOf(sizes).isRequired,
  className: PropTypes.string,
};

Button.defaultProps = {
  theme: themes[0],
  size: sizes[0],
};

export default Button;
export { sizes, themes };
