const simulationMockBase = {
  dadosEmpresa: {
    // isCorretoraAkad: true, // conditional
    cnpj: '14868712000131',
    razaoSocial: 'AKAD SEGUROS BRASIL S.A.',
    nomeFantasia: 'AKAD Seguros',
    email: 'regulatorio@akadseguros.com.br',
    enderecoLocalRisco: {
      cep: '04578-000',
      logradouro: 'AV DAS NACOES UNIDAS',
      numero: '12399',
      complemento: 'CONJ 140 E 141',
      bairro: 'BROKLIN PAULISTA',
      cidade: 'SAO PAULO',
      estado: 'SP',
    },
    // atividade: 1, // conditional
    telefonePrincipal: '(11) 30565 530',
    enderecoCobrancaMesmoLocalRisco: true,
  },
  avaliacaoRisco: {
    valorRiscoTotal: 300000,
    classeConstrucao: 1,
    isopainelConstrucao: false,
    imovelDesocupado: false,
    localizacaoRisco: 5,
    imovelTombado: false,
  },
  sistemaProtecional: {
    protecoes: [2],
  },
  coberturas: [
    {
      cobertura: 1,
      valor: 300000,
    },
    {
      cobertura: 3,
      valor: 15000,
    },
    {
      cobertura: 9,
      valor: 7500,
    },
    {
      cobertura: 15,
      valor: 30000,
    },
    {
      cobertura: 17,
      valor: 15000,
    },
    {
      cobertura: 18,
      valor: 5000,
    },
    {
      cobertura: 21,
      valor: 15000,
    },
    {
      cobertura: 25,
      valor: 30000,
    },
    {
      cobertura: 27,
      valor: 7500,
    },
    {
      cobertura: 34,
      valor: 30000,
    },
  ],
  finalizacao: {
    comissao: 20,
  },
  personalizado: false,
};

const simulationAkadBrokerOffice = {
  ...simulationMockBase,
  dadosEmpresa: {
    ...simulationMockBase.dadosEmpresa,
    isCorretoraAkad: true,
    atividade: 17,
  },
};
const simulationBusinessOffice = {
  ...simulationMockBase,
  dadosEmpresa: {
    ...simulationMockBase.dadosEmpresa,
    atividade: 17,
  },
};
const simulationDoctorsOffice = {
  ...simulationMockBase,
  dadosEmpresa: {
    ...simulationMockBase.dadosEmpresa,
    atividade: 12,
  },
};

const mockNewQuotation = {
  dadosEmpresa: {
    enderecoCobrancaMesmoLocalRisco: true,
  },
  avaliacaoRisco: {
    valorRiscoTotal: 20000,
    classeConstrucao: 1,
    isopainelConstrucao: false,
    imovelDesocupado: false,
    localizacaoRisco: 5,
    imovelTombado: false,
  },
  sistemaProtecional: {
    protecoes: [2],
  },
  coberturas: [
    {
      cobertura: 1,
      valor: 8000,
    },
  ],
  finalizacao: {
    comissao: 20,
  },
  personalizado: true,
};

export {
  simulationAkadBrokerOffice,
  simulationBusinessOffice,
  simulationDoctorsOffice,
  mockNewQuotation,
};
