import React, { useEffect, useContext, useCallback, useMemo } from 'react';
import { navigate } from 'gatsby';

import { DataContext } from 'data/DataProvider';

import { handleInvalidSession } from 'helpers/auth';

import DefaultLayout from 'components/layouts/default-layout';
import MainHeader from 'components/main-header/main-header';
import MainFooter from 'components/main-footer/main-footer';
import PlanCard from 'components/plan-card/plan-card';
import Button from 'components/button/button';

import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

import BrokerGiftImage from 'images/broker-gift.svg';

import { plansData } from 'mock/plansData';

import { mockNewQuotation } from 'mock/simulationData';

import './index.scss';

const block = 'landing';

const IndexPage = ({ location, ...rest }) => {
  const {
    setQuotation,
    brokerData,
    setModal,
    dismissibleGlobalError,
    setDismissibleGlobalError,
  } = useContext(DataContext);

  const initiateAkadBrokerModal = () => {
    setModal({
      values: {
        className: 'modal__special-condition',
        iconFull: BrokerGiftImage,
        headline: `Temos uma condição especial para você!`,
        message: (
          <>
            <strong>Corretor</strong>, ao cotar o seguro empresarial{' '}
            <strong>para a sua corretora,</strong> você terá acesso a{' '}
            <strong>um preço diferenciado e exclusivo.</strong>
            Aproveite essa oportunidade!
          </>
        ),
        primaryButton: {
          label: 'Entendi',
          handler: () => {},
          id: 'botao-modal-corretor-akad',
        },
        showHideCheckbox: true,
        modalId: 'CorretorAkad',
      },
    });
  };

  function handleAssistanceCta() {
    trackCustomEvent({
      category: 'Download de arquivo',
      action: 'Click',
      label: 'Assistência 24 horas',
    });
    window.open(
      `/pdf/Assistencia-Empresarial-IKE-2023.pdf`,
      '_blank',
      'noopener,noreferrer'
    );
  }

  function handleNewQuotation() {
    nextPage(mockNewQuotation, 'Nova cotação');
  }

  const nextPage = useCallback(
    (mockData, analyticsEventLabel) => {
      trackCustomEvent({
        category: 'Simulação escolhida',
        action: 'Click',
        label: analyticsEventLabel,
      });
      if (mockData && brokerData?.dadosBroker) {
        setQuotation({
          dadosBroker: brokerData?.dadosBroker,
          ...mockData,
        });
        navigate('/cnpj');
      } else {
        handleInvalidSession();
      }
    },
    [setQuotation, brokerData?.dadosBroker]
  );

  const plansDataWithHandlers = useMemo(() => {
    return plansData.map((plan) => ({
      ...plan,
      handler: () => nextPage(plan.simulationData, plan.title),
    }));
  }, [plansData]);

  useEffect(() => {
    if (!brokerData || !brokerData?.dadosBroker) {
      return handleInvalidSession();
    }
    if (dismissibleGlobalError) {
      setModal({
        values: {
          headline: 'Ops',
          message: dismissibleGlobalError.error,
          primaryButton: {
            label: 'Realizar nova cotação',
            handler: () => {
              setDismissibleGlobalError(null);
              navigate('/');
            },
          },
          secondaryButton: {
            label: 'Voltar para o digital',
            handler: () => {
              setDismissibleGlobalError(null);
              window.location.href = `${process.env.GATSBY_URL_DIGITAL}/protector/ferramentas/cotacoes`;
            },
          },
          dismissible: true,
        },
      });
    }
  }, [dismissibleGlobalError, setDismissibleGlobalError, setModal]);

  useEffect(() => {
    initiateAkadBrokerModal();
  }, []);

  return (
    <DefaultLayout>
      <MainHeader />
      <div className="container--large">
        <section className={`${block}__header`}>
          <div>
            <h1>
              <b>Cote e contrate</b> o seguro empresarial
              <br /> em <b>até 3 minutos</b>
            </h1>
            <p>
              Pensado sob medida para <b>comércio e serviços!</b> O seguro
              empresarial da Akad Seguros possui uma cotação{' '}
              <b>simples, ágil e flexível.</b>
            </p>
          </div>
          <div className={`${block}__cta`}>
            <Button
              color="primary"
              size="large"
              onClick={() => handleNewQuotation()}
            >
              Nova Cotação
            </Button>
            <p>Disponível para mais de 40 atividades</p>
          </div>
        </section>
        <section className={`${block}__plans`}>
          <h3>
            Faça uma <b>nova cotação</b> ou utilize uma de nossas{' '}
            <b>simulações</b>:
          </h3>
          <div className={`${block}__plans-grid`}>
            {plansDataWithHandlers.map((plan) => (
              <PlanCard key={plan.productType} plan={plan} btnId={plan.productType} />
            ))}
          </div>
        </section>
        <div className={`${block}__info-box`}>
          <div className={`${block}__info-box__title`}>
            <span className="material-icons icon">info</span>
            <h4>Informações</h4>
          </div>
          <ol>
            <li>
              Os <b>valores</b> das cotações rápidas acima{' '}
              <b>
                podem sofrer alterações conforme a personalização realizada.
              </b>
            </li>
            <li>
              Todas as cotações do produto Empresarial são{' '}
              <b>compostas por até 10 parcelas</b> no pix, boleto bancário ou
              cartão de crédito e a <b>renovação não é automática.</b>
            </li>
          </ol>
        </div>
        <section className={`${block}__assistance-banner`}>
          <span className="material-icons icon">construction</span>
          <div>
            <h4>Assistência 24h em todo território nacional</h4>
            <p>
              Para problemas hidráulicos, elétricos, chaveiro, quebra de vidro,
              limpeza, vigilância, desentupimento, telhados.
            </p>
          </div>
          <Button theme="secondary" size="large" onClick={handleAssistanceCta}>
            Saiba mais
          </Button>
        </section>
      </div>
      <MainFooter />
    </DefaultLayout>
  );
};

export default IndexPage;
